<template>
  <v-col class="px-0 py-0">
    <change-lang-form @setLang="setLang" />
    <div class="sectionBackground">
      <v-row no-gutters align="center">
        <p class="sectionTitle" style="margin-bottom: 0px">
          Поширені запитання
        </p>
        <v-row no-gutters justify="end">
          <p class="sectionText" style="margin-bottom: 0px">
            {{ FAQsList.length }}\12
          </p>
        </v-row>
      </v-row>
      <Loader v-if="showLoader" />
      <v-row
        no-gutters
        justify="center"
        style="margin: 60px 0px"
        v-else-if="FAQsList.length == 0"
      >
        <p class="sectionTitle" style="margin-bottom: 0px">Питання не додані</p>
      </v-row>
      <v-col v-else class="px-0 py-0">
        <v-expansion-panels v-model="panel" style="margin-top: 20px">
          <draggable v-model="FAQsList" style="width: 100%">
            <v-expansion-panel
              v-for="(item, index) in FAQsList"
              :key="item.id"
              style="margin-bottom: 20px; padding: 16px 0px; width: 100%"
              prepend-inner-icon="mdi-chevron-down"
            >
              <v-expansion-panel-header expand-icon="">
                <v-row no-gutters align="center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    style="margin-right: 20px"
                    @click="isShowContent = true"
                    :style="
                      activeItem == index ? 'transform:rotate(180deg)' : ''
                    "
                  >
                    <path
                      d="M18 9L12 15L6 9"
                      stroke="#4B5262"
                      stroke-width="1.75"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span
                    class="sectionTitle"
                    style="margin: 0px; width: 80%"
                    @click="isShowContent = true"
                  >
                    {{
                      item?.translations?.find(
                        (translate) => translate.lang == activeLang
                      )?.question
                    }}
                  </span>
                  <v-row
                    no-gutters
                    justify="end"
                    sty
                    @click="isShowContent = false"
                  >
                    <div
                      class="iconBackground"
                      style="margin-right: 12px; z-index: 20"
                      @click="
                        (isEdit = true),
                          (itemForEdit = item),
                          (isShowCreateNewFAQModal = true)
                      "
                    >
                      <div class="editIcon"></div>
                    </div>
                    <div
                      class="iconBackground"
                      style="margin-right: 12px"
                      @click="deleteFAQ(item.id)"
                    >
                      <div class="deleteIcon"></div>
                    </div>
                    <div class="iconBackground">
                      <div class="dragIcon"></div>
                    </div>
                  </v-row>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="isShowContent">
                {{
                  item?.translations?.find(
                    (translate) => translate.lang == activeLang
                  )?.answer
                }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
      <add-new-item-btn text="Додати" @click="isShowCreateNewFAQModal = true" />
    </div>
    <submit-button
      style="
        margin-top: 40px;
        height: 48px !important;
        font-size: 20px;
        font-weight: 700;
      "
      @click="saveOrdering"
      >Зберегти зміни</submit-button
    >
    <create-new-FAQ-modal
      v-if="isShowCreateNewFAQModal"
      :visible="isShowCreateNewFAQModal"
      :isEdit="isEdit"
      :itemForEdit="itemForEdit"
      :activeUserLang="activeLang"
      :index="FAQsList.length"
      @successCreate="
        (showLoader = true),
          (isShowCreateNewFAQModal = false),
          (isEdit = false),
          getFAQs()
      "
      @deleteItem="deleteFAQ"
      @close="(isShowCreateNewFAQModal = false), (isEdit = false)"
    />
  </v-col>
</template>

<script>
import addNewItemBtn from "../../../UI/Buttons/addNewItemBtn.vue";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import draggable from "vuedraggable";
import CreateNewFAQModal from "./createNewFAQModal.vue";
import FAQService from "../../../../requests/Admin/FAQService";
import ChangeLangForm from "../../../UI/Forms/changeLangForm.vue";
import Loader from "./../../../UI/Loader.vue";
import requestFormData from "../../../../requests/requestFormData";
export default {
  components: {
    addNewItemBtn,
    SubmitButton,
    draggable,
    CreateNewFAQModal,
    ChangeLangForm,
    Loader,
  },
  data: () => ({
    activeLang: "ua",
    FAQsList: [],
    panel: 0,
    activeItem: -1,
    page: 1,
    quantityPage: 0,
    active: false,
    isShowContent: false,
    isShowCreateNewFAQModal: false,
    isEdit: false,
    showLoader: true,
    itemForEdit: {},
  }),
  mounted() {
    this.getFAQs();
  },
  methods: {
    setLang(lang) {
      this.activeLang = lang;
    },
    async getFAQs() {
      await FAQService.getFAQs(this.page).then((res) => {
        if (res.status == "Success") {
          this.FAQsList = res.data.sort((a, b) => a.order - b.order);
          this.quantityPage = res.pagination.meta.last_page;
          this.showLoader = false;
        }
      });
    },
    async saveOrdering() {
      this.FAQsList.forEach(async (faq, index) => {
        let ua_translations = faq.translations.find(
          (translate) => translate.lang == "ua"
        );
        let en_translations = faq.translations.find(
          (translate) => translate.lang == "en"
        );
        let pl_translations = faq.translations.find(
          (translate) => translate.lang == "pl"
        );
        const data = {
          group: faq.group,
          order: index,
          translations: [
            {
              id: ua_translations.id,
              lang: "ua",
              question: ua_translations.question,
              answer: ua_translations.answer,
            },
            {
              id: en_translations.id,
              lang: "en",
              question: en_translations.question,
              answer: en_translations.answer,
            },
            {
              id: pl_translations.id,
              lang: "pl",
              question: pl_translations.question,
              answer: pl_translations.answer,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await FAQService.updateFAQ(faq.id, form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      });
    },
    async deleteFAQ(id) {
      this.isShowCreateNewFAQModal = false;
      this.isEdit = false;
      await FAQService.deleteFAQ(id).then(() => {
        this.showLoader = true;
        this.getFAQs();
      });
    },
  },
  watch: {
    panel: {
      deep: true,
      handler() {
        this.activeItem = this.panel;
        this.active = true;
      },
    },
  },
};
</script>

<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>