<template>
  <v-dialog v-model="visibility" width="720px">
    <div style="background: #fff; padding: 16px">
      <v-row no-gutters align="center">
        <span class="sectionTitle" style="margin: 0px"
          >{{ isEdit ? "Оновлення питання" : "Нове питання" }}
        </span>
        <v-row no-gutters align="center" justify="end" v-if="isEdit">
          <v-btn
            class="actionBtn"
            style="color: #df3a3a; background: rgba(223, 58, 58, 0.1)"
            @click="$emit('deleteItem', faq.id)"
            >Видалити</v-btn
          >
        </v-row>
      </v-row>
      <div class="sectionLine" style="margin: 20px 0px"></div>
      <v-row no-gutters align="center" style="margin-bottom: 20px">
        <div
          class="statusBox"
          :class="activeLang == 'ua' ? 'activeStatusBox' : ''"
          @click="activeLang = 'ua'"
        >
          Українська
        </div>
        <div
          class="statusBox"
          :class="activeLang == 'en' ? 'activeStatusBox' : ''"
          @click="activeLang = 'en'"
        >
          English
        </div>
        <div
          class="statusBox"
          :class="activeLang == 'pl' ? 'activeStatusBox' : ''"
          @click="activeLang = 'pl'"
        >
          Polish
        </div>
      </v-row>
      <v-text-field
        outlined
        dense
        label="Питання"
        placeholder="Введіть тут.."
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        height="48px"
        v-model="questionModel"
        :error-messages="questionError"
      />
      <v-textarea
        outlined
        dense
        label="Відповідь"
        placeholder="Введіть тут.."
        style="border-radius: 10px"
        color="#E2E2E2"
        background-color="#FFF"
        min-height="100px"
        v-model="answerModel"
        :error-messages="answerError"
      />
      <submit-button
        style="
          height: 48px !important;
          margin-top: 20px;
          font-size: 20px;
          font-weight: 700;
        "
        @click="isEdit ? updateFAQ() : createFAQ()"
        >{{ isEdit ? "Оновити" : "Створити" }}</submit-button
      >
      <v-row no-gutters justify="center">
        <cancel-btn
          text="Скасувати"
          style="margin-top: 10px"
          @click="$emit('close')"
        />
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import modalMixin from "../../../../mixins/modalMixin";
import CancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import { required } from "vuelidate/lib/validators";
import requestFormData from "../../../../requests/requestFormData";
import FAQService from "./../../../../requests/Admin/FAQService.js";
export default {
  components: { submitButton, CancelBtn },
  mixins: [modalMixin, validationMixin],
  data: () => ({
    activeLang: "ua",
    faq: {
      question: "",
      question_en: "",
      question_pl: "",
      answer: "",
      answer_en: "",
      answer_pl: "",
    },
  }),
  props: {
    isEdit: {
      require: false,
    },
    itemForEdit: {
      require: false,
    },
    activeUserLang: {
      require: false,
    },
    index: {
      require: false,
    },
  },
  validations: {
    faq: {
      question: {
        required,
      },
      answer: {
        required,
      },
    },
  },
  mounted() {
    if (this.activeUserLang) {
      this.activeLang = this.activeUserLang;
    }
    if (this.isEdit) {
      this.setFAQForEdit();
    }
  },
  methods: {
    async createFAQ() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          group: "default",
          order: this.index + 1,
          translations: [
            {
              lang: "ua",
              question: this.faq.question,
              answer: this.faq.answer,
            },
            {
              lang: "en",
              question:
                this.faq.question_en !== ""
                  ? this.faq.question_en
                  : this.faq.question,
              answer:
                this.faq.answer_en !== ""
                  ? this.faq.answer_en
                  : this.faq.answer,
            },
            {
              lang: "pl",
              question:
                this.faq.question_pl !== ""
                  ? this.faq.question_pl
                  : this.faq.question,
              answer:
                this.faq.answer_pl !== ""
                  ? this.faq.answer_pl
                  : this.faq.answer,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await FAQService.createFAQ(form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    async updateFAQ() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          group: this.faq.group,
          translations: [
            {
              id: this.faq.question_ua_id,
              lang: "ua",
              question: this.faq.question,
              answer: this.faq.answer,
            },
            {
              id: this.faq.question_en_id,
              lang: "en",
              question:
                this.faq.question_en !== ""
                  ? this.faq.question_en
                  : this.faq.question,
              answer:
                this.faq.answer_en !== ""
                  ? this.faq.answer_en
                  : this.faq.answer,
            },
            {
              id: this.faq.question_pl_id,
              lang: "pl",
              question:
                this.faq.question_pl !== ""
                  ? this.faq.question_pl
                  : this.faq.question,
              answer:
                this.faq.answer_pl !== ""
                  ? this.faq.answer_pl
                  : this.faq.answer,
            },
          ],
        };
        let form = requestFormData.jsonToFormData(data);
        await FAQService.updateFAQ(this.faq.id, form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      }
    },
    setFAQForEdit() {
      this.$set(this.faq, "id", this.itemForEdit.id);
      this.$set(this.faq, "group", this.itemForEdit.group);
      this.$set(
        this.faq,
        "question_ua_id",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "ua"
        )?.id
      );
      this.$set(
        this.faq,
        "question_en_id",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "en"
        )?.id
      );
      this.$set(
        this.faq,
        "question_pl_id",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "pl"
        )?.id
      );
      this.$set(
        this.faq,
        "question",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "ua"
        )?.question
      );
      this.$set(
        this.faq,
        "question_en",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "en"
        )?.question
      );
      this.$set(
        this.faq,
        "question_pl",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "pl"
        )?.question
      );
      this.$set(
        this.faq,
        "answer",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "ua"
        )?.answer
      );
      this.$set(
        this.faq,
        "answer_en",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "en"
        )?.answer
      );
      this.$set(
        this.faq,
        "answer_pl",
        this.itemForEdit.translations.find(
          (translate) => translate.lang == "pl"
        )?.answer
      );
    },
  },
  computed: {
    questionModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.faq.question;
          }
          case "en": {
            return this.faq.question_en;
          }
          case "pl": {
            return this.faq.question_pl;
          }
        }
        return this.faq.question;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.faq.question = val;
            }
            break;
          case "en":
            {
              this.faq.question_en = val;
            }
            break;
          case "pl":
            {
              this.faq.question_pl = val;
            }
            break;
        }
      },
    },
    answerModel: {
      get() {
        switch (this.activeLang) {
          case "ua": {
            return this.faq.answer;
          }
          case "en": {
            return this.faq.answer_en;
          }
          case "pl": {
            return this.faq.answer_pl;
          }
        }
        return this.faq.answer;
      },
      set(val) {
        switch (this.activeLang) {
          case "ua":
            {
              this.faq.answer = val;
            }
            break;
          case "en":
            {
              this.faq.answer_en = val;
            }
            break;
          case "pl":
            {
              this.faq.answer_pl = val;
            }
            break;
        }
      },
    },
    questionError() {
      const errors = [];
      let field = this.$v.faq.question;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    answerError() {
      const errors = [];
      let field = this.$v.faq.answer;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.actionBtn {
  width: 160px !important;
  padding: 12px 16px;
  height: 46px !important;
  border-radius: 10px;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none !important;
}
</style>